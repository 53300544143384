import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SocialLinks from '../Social';
import { routes } from '../../constants';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

function BasicExample() {
  return (
    <Navbar expand="lg" className="bg-black">
      <Container>
			<Navbar.Brand href="/">
				<img src="/logo.png" style={{ height: 40, width: 100, borderRadius: 4 }} alt=''/>
          </Navbar.Brand>
       {/* <Navbar.Toggle aria-controls="basic-navbar-nv" className="custom-toggle" /> */}
			 <Navbar.Toggle>
			 <MenuOutlined className="trigger"  />
			 </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
									{routes.map((routes) => {
				return <Nav.Link href={routes.route} onClick={window.scrollTo(0, 0)}>
					{routes.label}
				</Nav.Link >
			})}
						<Nav.Item style={{display: "flex", justifyContent: "center"}}><SocialLinks size={20}/></Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;