import React from 'react';
import { Layout, Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';
import { convocatoriaImages } from '../../constants';
import CarouselComponent from '../../Components/Carousel';

const { Content } = Layout;
const { Meta } = Card;

const Convocatoria = () => {

	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"Convocatoria"} />
				<div className='one'>
					<CarouselComponent images={convocatoriaImages} />

					<div style={{marginTop: 40}}>
						<h1  className='subtitle'>
							ACERCA DE LA 4A EDICIÓN DEL ULTRAMARATÓN
						</h1>

						<h2  className='subtitle'>
							SALIDA Y META
						</h2>
						Explanada Municipal de Totontepec Villa de Morelos, Mixe,
						Oaxaca, México.

						<h2  className='subtitle'>
							HORARIOS DE SALIDA
						</h2>

						<p>100k y 53k - sábado 25 de noviembre, 5:00am</p>
						<p>30k - domingo 26 de noviembre, 6:00am</p>
						<p>15k - domingo 26 de noviembre,  6:30am</p>

						<h3 className='subtitle-o'>
							CATEGORÍAS  100k, 53k Y 30k
						</h3>

						<p>Libre: 18-39 años.</p>
						<p>Máster: 40-49 años. </p>
						<p>Veteranos: 50 en adelante.</p>
						Ramas varonil y femenil

						<h3 className='subtitle-o'>
							15k
						</h3>
						Categoría única
						Ramas varonil y femenil

						<h2  className='subtitle'>
							PREMIACIÓN
						</h2>
						100,000 pesos en premios a distribuirse entre todas las categorías y ramas. Se premia por igual a hombres y mujeres.

						<h2 className='subtitle'>
							RUTA
							</h2>

<p>Además de Totontepec, la ruta de 100k toca un total de cinco comunidades vecinas: Jareta, Móctum, Tepitongo, Amatepec, Tonaguía y Ocotepec. Esto convierte la carrera en un evento que promueve la unión entre comunidades llevando la alegría y la emoción de una carrera de montaña.</p>


					</div>
				</div>

			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Convocatoria;