import React from 'react';
import { Timeline } from 'antd';
import HeaderComponent from '../../Components/Header';
import Title from '../../Components/Title';
import FooterComponent from '../../Components/Footer';
import { WhatsAppOutlined } from '@ant-design/icons';
import { transporteInfo } from '../../constants';
// import 'antd/dist/antd.css'; // Importa el estilo CSS principal de Ant Design
import Fade from "react-reveal"




const Info = () => {
  return (
    <div>
      <HeaderComponent />
      <Title title={"Información"} />
      <div className='one'>
        <div className='timeline-container'>
          <h1  className='subtitle-one'>Actividades</h1>
            <Fade bottom>
          <Timeline mode="alternate" >

            <Timeline.Item color="green">
              <h4 className='subtitle-o'>VIERNES 24 DE NOVIEMBRE</h4>
              <p><b>11:00 hrs:</b> Inauguración de la muestra gastronómica y cultural.</p>
              <p><b>12:00 a 22:00 hrs:</b> Entrega de paquetes de corredor de 53 y 100k.</p>
              <p><b>16:00 hrs:</b> Recepción de corredores.</p>
              <p><b>17:00 hrs:</b> Programa cultural e inauguración de la muestra de biodiversidad.</p>
              <p><b>18:00 hrs:</b> Reunión informativa 53 y 100k.</p>
              <p><b>19:00 hrs:</b> Calenda. Cena de carbohidratos.</p>
            </Timeline.Item>

            <Timeline.Item color="blue">
              <h4 className='subtitle-o'>SÁBADO 25 DE NOVIEMBRE</h4>
              <p><b>4:00 hrs:</b> Registro de corredores de 53 y 100K.</p>
              <p>Revisión de equipo obligatorio 100k.</p>
              <p>Recepción de dropbags.</p>
              <p><b>5:00 hrs:</b> Salida de 53 y 100k.</p>
              <p><b>10:00 a 12:00 hrs:</b> Carrera Infantil "Huellita de Tapir".</p>
              <p><b>12:00 a 22:00 hrs:</b> Entrega de paquetes de corredor 15 v 30k.</p>
              <p><b>20:00 a 22:00 hrs:</b> Devolución de dropbags.</p>
              <p>Contaremos con un programa cultural a lo largo del día.</p>
            </Timeline.Item>

            <Timeline.Item color="red">
              <h4 className='subtitle-o'>DOMINGO 26 DE NOVIEMBRE</h4>
              <p><b>5:00 hrs:</b> Cierre de la carrera de 100k.</p>
              <p><b>6:00 hrs:</b> Salida de 30k.</p>
              <p><b>6:30 hrs:</b> Salida de 15k.</p>
              <p><b>10:00 a 12:00 hrs:</b> Devolución de dropbags 53 y 100k.</p>
              <p><b>13:00 hrs:</b> Ceremonia de premiación y clausura.</p>
            </Timeline.Item>
          </Timeline>
            </Fade>
        </div>
        <div>
          <h2  className='subtitle'>Hospedaje</h2>

          <div>
            <p>
              Para alojar a todos los atletas y visitantes hemos creado un programa en el que las familias de Totontepec comparten sus casas y habitaciones a precios preferentes.
              Para reservar tu hospedaje envía un mensaje de Whatsapp a cualquiera de los siguientes números:

            </p>
            <p>
              <a href="https://api.whatsapp.com/send?phone=5539105659" target="_blank" rel="noopener noreferrer">
                <WhatsAppOutlined style={{ fontSize: '20px', marginRight: '8px', color: '#25d366' }} />
              </a>
              <b>
                951 160 3850
              </b>
              {" "}con Azarel Rivera
            </p>
            <p>
              <a href="https://api.whatsapp.com/send?phone=5539105659" target="_blank" rel="noopener noreferrer">
                <WhatsAppOutlined style={{ fontSize: '20px', marginRight: '8px', color: '#25d366' }} />
              </a>
              <b>
                951 234 3257
              </b>
              {" "}con Maricela Guzmán
            </p>
            <p>
              Debido a las condiciones de comunicación en la Sierra Mixe por lo regular te responderemos al cabo de algunas horas Gracias por tu paciencia y comprensión.
              ¡Con tu visita apoyas la economía de Totontepec!
            </p>
          </div>
        </div>
        <div style={{marginTop: 100}}>
          <h2  className='subtitle'>Transporte</h2>
          <div>
          {transporteInfo.map((elem) => <div style={{margin: 40}}> 
            <h4 className='subtitle-o'>

            {elem.tipo}
            </h4>
            <p>{elem.persona}</p>
            <p>{elem.hora}</p>
          </div>)}
          </div>
        </div>

      </div>
      <FooterComponent />
    </div>
  );;
};
export default Info;

// export default Info