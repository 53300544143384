import React from 'react';
import { Layout, theme } from 'antd';
import CarouselComponent from '../../Components/Carousel';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import { convocatoriaImages, homeImages } from '../../constants';

const { Content } = Layout;

const Home = () => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<div className="site-layout-content" style={{ background: colorBgContainer, marginTop: 0, width: "100%", padding: 0}}>
					<CarouselComponent images={convocatoriaImages}/>
				</div>
			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Home;