import React from 'react';

import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Bounce } from 'react-reveal';

const SocialLinks = ({size = 30}) => (
  <div style={{display: "flex", flexDirection: "row", width: "100%", maxWidth: 100, justifyContent: "center", alignItems: "center"}}>
    <Bounce>

    <a href="https://www.facebook.com/ultramaratonsierramixe/" target="_blank" rel="noopener noreferrer">
      <FacebookOutlined style={{ fontSize: size + "px", marginRight: '10px', marginLeft: "5px", color: '#3b5999' }} />
    </a>
    <a href="https://www.instagram.com/ultramaratonsierramixe/" target="_blank" rel="noopener noreferrer">
      <InstagramOutlined style={{ fontSize: size + "px", marginRight: '10px', marginLeft: "5px", color: '#e4405f' }} />
    </a>
    <a href="https://api.whatsapp.com/send?phone=5539105659" target="_blank" rel="noopener noreferrer">
      <WhatsAppOutlined style={{ fontSize: size + "px", marginRight: '10px', marginLeft: "5px", color: '#25d366' }} />
    </a>
    </Bounce>
  </div>
);

export default SocialLinks