import { Card } from "antd";
import React from "react";

function TimeComponentList({ header, year, data, reverse, color }) {
	return (<div style={{ margin: "auto", display: "flex", flexDirection: reverse ? "row-reverse" : "row", justifyContent: "space-evenly" }} className="history-item">
		<div style={{ flex: "1 1 100px", padding: 30, textAlign: "center", alignItems: "center", justifyContent: "center", display: "flex" }} className="time-header">
			<h3 style={{fontWeight: "bold", fontSize: 20}}>
			{header}
			</h3>
		</div>
		<div style={{ flex: "1 1 100px", padding: 30, textAlign: "center", justifyContent: "center", display: "flex" }} className="time-circle">
			<div style={{ width: 200, height: 200, backgroundColor: color, color: "white", display: "flex", borderRadius: "50%", textAlign: "center", alignItems: "center", verticalAlign: "center", justifyContent: "center" }}>
				<h1 style={{fontSize: 35}}>
					{year}
				</h1>
			</div>
		</div>
		<div style={{ flex: "1 1 250px", padding: 30, textAlign: "justify", alignItems: "center", justifyContent: "center", display: "flex" }} className="time-info">
			<Card hoverable>
				<p>
					{data}
				</p>
			</Card>
		</div>
	</div>)
}

export default TimeComponentList