import React from 'react';
import { Image, Layout } from 'antd';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import TimeComponentList from '../../Components/TimeComponentList';
import Title from '../../Components/Title';
import { Fade } from 'react-reveal';

const { Content } = Layout;

const About = () => {


	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"¿Quienes somos?"} />
				<div className='two'>
					<div className="about-content">
						<Fade>
							<div className='about-content-div'>
								<div className='about-content-div-div'>
									<Image
										// width={200}
										src="./about.jpg"
										style={{ borderRadius: 20 }}
									/>
								</div>
								<div style={{ padding: 20, flex: "1 1 0px" }}>
									<p>Somos una organización, sin fines
										de lucro, que buscamos promover
										un evento de ultradistancia que
										ofrezca a los participantes, una
										experiencia inlvidable, cobijada por
										la cultura y la riqueza natural de la
										sierra Mixe.
										Además se busca un cambio de vida
										saludable físico y mental entre,
										organizadores, participantes,
										visitantes y pobladores.
									</p>
								</div>
							</div>
						</Fade>
					</div>
					<Fade>
						<div>
							<h2 className='subtitle'>
								PERFIL DE LA COMUNIDAD
							</h2>
							<p>Totontepec Villa de Morelos es una comunidad indígena, con más de 2000 años de antigüedad situada en el corazón de las montañas de Oaxaca, México. Rodeada de una exuberante belleza natural y una rica herencia cultural, la comunidad se encuentra 150 kilómetros al noreste de la ciudad de Oaxaca.</p>
							<h2 className='subtitle'>Cultura y Tradiciones:</h2>
<p>La comunidad indígena de Totontepec Villa de Morelos se enorgullece de su herencia mixe, una cultura arraigada en siglos de tradiciones. Los habitantes de Totontepec valoran profundamente su idioma mixe, la amplia tradición de músicos y compositores y su rica historia oral. Las celebraciones comunitarias, que involucran música, danzas y platillos típicos, son vitales para la cohesión social y el mantenimiento de sus raíces culturales.</p>

<h2 className='subtitle'>Desafíos y Determinación:</h2>
<p>A pesar de la riqueza cultural y la fuerte identidad de Totontepec, la comunidad también enfrenta desafíos socioeconómicos. La falta de acceso a recursos educativos, oportunidades de empleo y servicios básicos ha sido una constante preocupación.</p>

<p>El municipio cuenta con un total de casi 6000 habitantes y en 2020, 49.4% de la población se encontraba en situación de pobreza moderada y 41.9% en situación de pobreza extrema de acuerdo con cifras oficiales. Sin embargo, la comunidad está decidida a superar estos desafíos a través de la colaboración, el ingenio y el espíritu de trabajo en equipo.</p>

						</div>
					</Fade>
					<Fade bottom>
						<h2 className='subtitle'>Historia</h2>

						<div style={{ padding: 40 }} className='history'>
							<TimeComponentList color={"#FFD700"} header="PRIMERA CARRERA ATLÉTICA" year="2017" data="En el año 2017, Félix Flores realiza el primer
evento deportivo, con una distancia de 10 Km,
en Totontepec Villa de Morelos, el cuál sería la
base para dar inicio al ULTRAMARATóN
SIERRA MIXE."/>
							<TimeComponentList color={"#FFA500"} header="PRIMERA EDICIÓN" year="2018" data="En este año, se conforma un grupo de
voluntarios, y en conjunto con el
Comisariado de Bienes Comunales de
Totontepec, logran realizar la primera
edición del ULTRAMARATóN SIERRA MIXE,
con las distancias de 15 Km, 32 Km, 50Km y
70 Km." reverse={true} />
							<TimeComponentList color={"#00FFFF"} header="SEGUNDA EDICIÓN" year="2019" data="Para esta segunda edición, asisten mas de
500 corredores en las distancias de 15Km,
30Km, 50Km y se crea la prueba máxima
de 100 Km, y se conforma el club
SALVAJES MIXES, con corredores locales,
que representarían a la Sierra Mixe en
múltiples competencias, obteniendo varios
pódiums en estas."/>
							<TimeComponentList color={"#FF1493"} header="TERCERA EDICIÓN" year="2022" data="La pandemia COVID-19, retrasa la tercera
edición, aplazándola para el año 2022,
alcanzando la inscripción, de casi 600
corredores de 20 estados de la república
Mexicana, así como de diferentes países:
Costa Rica, Estados Unidos, España y
Eslovenia, además se realiza la primera
carrera infantil huellita de tapir,
organizada por CONBIODES, A.C." reverse={true} />
							<TimeComponentList color={"#800080"} header="CUARTA EDICIÓN" year="2023" data="Para esta cuarta edición se espera la
participación de aproximadamente 800
corredores internacionales, nacionales,
estatales y locales, y posicionar el
ultratramaratón como uno de los eventos
mas importantesdelpaís."/>

						</div>
					</Fade>
				</div>
				<Image
										// width={200}
										src="https://scontent.fmex5-1.fna.fbcdn.net/v/t39.30808-6/406421550_860814402499556_6576241578774904331_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=3635dc&_nc_eui2=AeGHvLK0dNlPFUYALdRJ9IpgMf4lKaseAAgx_iUpqx4ACH8JFu7nL5BhUGWxmFTsEWAioAXitFIsa0igOxP_9qY-&_nc_ohc=wod1l2n5UtcAX_gJhWg&_nc_ht=scontent.fmex5-1.fna&oh=00_AfBmzmFXnE08hOG3epseuec3aaseS6OEDFDWJeVTMp3-AQ&oe=656DEB70"
										style={{ borderRadius: 20, width: "100%", margin: "80px auto", maxWidth: 800 }}
									/>
			</Content>

			<FooterComponent />
		</Layout>
	);
};

export default About;