import React from 'react';
import { Breadcrumb, Layout, Menu, theme, Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import MenuComponent from '../../Components/Menu';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import CarouselComponent from '../../Components/Carousel';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';

const { Header, Content, Footer } = Layout;
const { Meta } = Card;

const Cincuenta = () => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"50K"} />

			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Cincuenta;