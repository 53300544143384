import React from "react";

function Title({title}) {
	return <div className="background">
  <div className="title">
    <h1 >{title}</h1>
  </div>
</div>
	
}

export default Title