import React, { useState } from 'react';
import { Layout, Card, Avatar, Modal, Image } from 'antd';
import { patrocinadoresInfo, rutasInformacion } from '../../constants';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';
import { faLocationDot, faMountainSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const { Content } = Layout;
const { Meta } = Card;

const Patrocinadores = () => {
	const [open, setOpen] = useState(false);


	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"¿Còmo puedes apoyarnos?"} />
				<div className='one'>

					<div>
						<div>
							<h1 className='subtitle-one'>Patrocinadores</h1>
							<div>
								{patrocinadoresInfo.patrocinadores.map((elem) => <Image
									width={200}
									src={elem.image}
									style={{ borderRadius: 20 }}
								/>)}
							</div>
						</div>
						<div>
							<h1 className='subtitle-one'>Organizadores</h1>
							<div>
								{patrocinadoresInfo.organizadores.map((elem) => <Image
									width={200}
									src={elem.image}
									style={{ borderRadius: 20 }}
								/>)}
							</div>
						</div>
					</div>
				</div>

			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Patrocinadores;