export const routes = [
	{ label: "¿Quiénes somos?", route: "about" },
	{ label: "Rutas", route: "/rutas" },
	{ label: "Convocatoria", route: "/convocatoria" },
	{ label: "Actividades y hospedaje", route: "/info" },
	{ label: "Huellita de tapir", route: "/huellita" },
	{ label: "¿Cómo puedes apoyarnos?", route: "/patrocinadores" },
	{ label: "Contacto", route: "/contacto" },
	// { label: "Inscríbete", route: "/inscripcion" },
]

export const convocatoriaImages =  [
	{
  original: "/images/convocatoria/1.jpg",
  thumbnail: "images/convocatoria/1.jpg"
},{
  original: "/images/convocatoria/2.jpg",
  thumbnail: "images/convocatoria/2.jpg"
},{
  original: "/images/convocatoria/3.jpg",
  thumbnail: "images/convocatoria/3.jpg"
},{
  original: "/images/convocatoria/4.jpg",
  thumbnail: "images/convocatoria/4.jpg"
},{
  original: "/images/convocatoria/5.jpg",
  thumbnail: "images/convocatoria/5.jpg"
},{
  original: "/images/convocatoria/6.jpg",
  thumbnail: "images/convocatoria/6.jpg"
},{
  original: "/images/convocatoria/7.jpg",
  thumbnail: "images/convocatoria/7.jpg"
},{
  original: "/images/convocatoria/8.jpg",
  thumbnail: "images/convocatoria/8.jpg"
},{
  original: "/images/convocatoria/9.jpg",
  thumbnail: "images/convocatoria/9.jpg"
},{
  original: "/images/convocatoria/10.jpg",
  thumbnail: "images/convocatoria/10.jpg"
},{
  original: "/images/convocatoria/11.jpg",
  thumbnail: "images/convocatoria/11.jpg"
},{
  original: "/images/convocatoria/12.jpg",
  thumbnail: "images/convocatoria/12.jpg"
},{
  original: "/images/convocatoria/13.jpg",
  thumbnail: "images/convocatoria/13.jpg"
},{
  original: "/images/convocatoria/14.jpg",
  thumbnail: "images/convocatoria/14.jpg"
},{
  original: "/images/convocatoria/15.jpg",
  thumbnail: "images/convocatoria/15.jpg"
},{
  original: "/images/convocatoria/16.jpg",
  thumbnail: "images/convocatoria/16.jpg"
},{
  original: "/images/convocatoria/17.jpg",
  thumbnail: "images/convocatoria/17.jpg"
},{
  original: "/images/convocatoria/18.jpg",
  thumbnail: "images/convocatoria/18.jpg"
}]

export const homeImages =  [{
  original: "/images/home/1.jpg",
  thumbnail: "images/home/1.jpg"
},{
  original: "/images/home/2.jpg",
  thumbnail: "images/home/2.jpg"
},{
  original: "/images/home/3.jpg",
  thumbnail: "images/home/3.jpg"
},{
  original: "/images/home/4.jpg",
  thumbnail: "images/home/4.jpg"
},{
  original: "/images/home/5.jpg",
  thumbnail: "images/home/5.jpg"
},{
  original: "/images/home/6.jpg",
  thumbnail: "images/home/6.jpg"
},{
  original: "/images/home/7.jpg",
  thumbnail: "images/home/7.jpg"
},{
  original: "/images/home/8.jpg",
  thumbnail: "images/home/8.jpg"
},{
  original: "/images/home/9.jpg",
  thumbnail: "images/home/9.jpg"
},{
  original: "/images/home/10.jpg",
  thumbnail: "images/home/10.jpg"
},{
  original: "/images/home/11.jpg",
  thumbnail: "images/home/11.jpg"
}]

export const rutasInformacion = [
	{
		title: "15k",
		titleimg: "./images/rutasimg/11.jpg",
		info: "Una ruta que exigirá lo mejor de ti, pasa por el bosque nublado, ecosistema rico en biodiversidad",
		desc1: ["Mochila/cinturón de hidratación", "silbato"],
		desc2: "Equipo sugerido: Bastones, guantes, barras energéticas",
		puntos: 3,
		altimetria: "",
		mapa: "",
		page: "15k"
	},
	{
		title: "30k",
		titleimg: "./images/rutasimg/10.jpg",
		info: "Una ruta que exigirá lo mejor de ti, pasa por dos comunidades y también por el bosque nublado, ecosistema rico en biodiversidad",
		desc1: ["Mochila/cinturón de hidratación", "silbato"],
		desc2: "Equipo sugerido: Bastones, guantes, barras energéticas",
		puntos: 3,
		altimetria: "",
		mapa: "",
		page: "30k"
	},
	{
		title: "53k",
		titleimg: "./images/rutasimg/9.jpg",
		info: "Una ruta muy completa, que pasa por cuatro diferentes comunidades, cruzando la espesa vegetación del bosque nublado, con impresionantes helechos arborescentes y cascadas de mas de 40 m de altura.",
		desc1: ["Lámpara frontal", "mochila de hidratación", "silbato", "rompevientos impermeable", "manta térmica"],
		desc2: "Equipo sugerido: Bastones, guantes, barras energéticas",
		puntos: 3,
		altimetria: "",
		mapa: "",
		page: "53k"
	},
	{
		title: "100k",
		titleimg: "./images/rutasimg/8.jpg",
		info: "Una ruta espectacular, a lo largo del majestuoso bosque nublado, considerado el hogar del tapir y jaguar, lleno de riqueza natural, y que cuenta con un alto nivel técnico. Este año la ruta tocará un total de seis comunidades",
		desc1: ["Lámpara frontal", "mochila de hidratación", "silbato", "rompevientos impermeable", "manta térmica"],
		desc2: "Equipo sugerido: Bastones, guantes, barras energéticas",
		puntos: 3,
		altimetria: "",
		mapa: "",
		page: "100k"
	}
]


export const countryOptions = [
	{ value: 'AF', label: 'Afghanistan (+93)' },
	{ value: 'AL', label: 'Albania (+355)' },
	{ value: 'DZ', label: 'Algeria (+213)' },
	{ value: 'AD', label: 'Andorra (+376)' },
	{ value: 'AO', label: 'Angola (+244)' },
	{ value: 'AI', label: 'Anguilla (+1-264)' },
	{ value: 'AG', label: 'Antigua and Barbuda (+1-268)' },
	{ value: 'AR', label: 'Argentina (+54)' },
	{ value: 'AM', label: 'Armenia (+374)' },
	{ value: 'AW', label: 'Aruba (+297)' },
	{ value: 'AU', label: 'Australia (+61)' },
	{ value: 'AT', label: 'Austria (+43)' },
	{ value: 'AZ', label: 'Azerbaijan (+994)' },
	{ value: 'BS', label: 'Bahamas (+1-242)' },
	{ value: 'BH', label: 'Bahrain (+973)' },
	{ value: 'BD', label: 'Bangladesh (+880)' },
	{ value: 'BB', label: 'Barbados (+1-246)' },
	{ value: 'BY', label: 'Belarus (+375)' },
	{ value: 'BE', label: 'Belgium (+32)' },
	{ value: 'BZ', label: 'Belize (+501)' },
	{ value: 'BJ', label: 'Benin (+229)' },
	{ value: 'BM', label: 'Bermuda (+1-441)' },
	{ value: 'BT', label: 'Bhutan (+975)' },
	{ value: 'BO', label: 'Bolivia (+591)' },
	{ value: 'BA', label: 'Bosnia and Herzegovina (+387)' },
	{ value: 'BW', label: 'Botswana (+267)' },
	{ value: 'BR', label: 'Brazil (+55)' },
	{ value: 'IO', label: 'British Indian Ocean Territory (+246)' },
	{ value: 'VG', label: 'British Virgin Islands (+1-284)' },
	{ value: 'BN', label: 'Brunei (+673)' },
	{ value: 'BG', label: 'Bulgaria (+359)' },
	{ value: 'BF', label: 'Burkina Faso (+226)' },
	{ value: 'BI', label: 'Burundi (+257)' },
	{ value: 'KH', label: 'Cambodia (+855)' },
	{ value: 'CM', label: 'Cameroon (+237)' },
	{ value: 'CA', label: 'Canada (+1)' },
	{ value: 'CV', label: 'Cape Verde (+238)' },
	{ value: 'KY', label: 'Cayman Islands (+1-345)' },
	{ value: 'CF', label: 'Central African Republic (+236)' },
	{ value: 'TD', label: 'Chad (+235)' },
	{ value: 'CL', label: 'Chile (+56)' },
	{ value: 'CN', label: 'China (+86)' },
	{ value: 'CX', label: 'Christmas Island (+61)' },
	{ value: 'CC', label: 'Cocos Islands (+61)' },
	{ value: 'CO', label: 'Colombia (+57)' },
	{ value: 'KM', label: 'Comoros (+269)' },
	{ value: 'CK', label: 'Cook Islands (+682)' },
	{ value: 'CR', label: 'Costa Rica (+506)' },
	{ value: 'HR', label: 'Croatia (+385)' },
	{ value: 'CU', label: 'Cuba (+53)' },
	{ value: 'CW', label: 'Curacao (+599)' },
	{ value: 'CY', label: 'Cyprus (+357)' },
	{ value: 'CZ', label: 'Czech Republic (+420)' },
	{ value: 'CD', label: 'Democratic Republic of the Congo (+243)' },
	{ value: 'DK', label: 'Denmark (+45)' },
	{ value: 'DJ', label: 'Djibouti (+253)' },
	{ value: 'DM', label: 'Dominica (+1-767)' },
	{ value: 'DO', label: 'Dominican Republic (+1-809 and +1-829)' },
	{ value: 'TL', label: 'East Timor (+670)' },
	{ value: 'EC', label: 'Ecuador (+593)' },
	{ value: 'EG', label: 'Egypt (+20)' },
	{ value: 'SV', label: 'El Salvador (+503)' },
	{ value: 'GQ', label: 'Equatorial Guinea (+240)' },
	{ value: 'ER', label: 'Eritrea (+291)' },
	{ value: 'EE', label: 'Estonia (+372)' },
	{ value: 'ET', label: 'Ethiopia (+251)' },
	{ value: 'FK', label: 'Falkland Islands (+500)' },
	{ value: 'FO', label: 'Faroe Islands (+298)' },
	{ value: 'FJ', label: 'Fiji (+679)' },
	{ value: 'FI', label: 'Finland (+358)' },
	{ value: 'FR', label: 'France (+33)' },
	{ value: 'PF', label: 'French Polynesia (+689)' },
	{ value: 'GA', label: 'Gabon (+241)' },
	{ value: 'GM', label: 'Gambia (+220)' },
	{ value: 'GE', label: 'Georgia (+995)' },
	{ value: 'DE', label: 'Germany (+49)' },
	{ value: 'GH', label: 'Ghana (+233)' },
	{ value: 'GI', label: 'Gibraltar (+350)' },
	{ value: 'GR', label: 'Greece (+30)' },
	{ value: 'GL', label: 'Greenland (+299)' },
	{ value: 'GD', label: 'Grenada (+1-473)' },
	{ value: 'GU', label: 'Guam (+1-671)' },
	{ value: 'GT', label: 'Guatemala (+502)' },
	{ value: 'GG', label: 'Guernsey (+44-1481)' },
	{ value: 'GN', label: 'Guinea (+224)' },
	{ value: 'GW', label: 'Guinea-Bissau (+245)' },
	{ value: 'GY', label: 'Guyana (+592)' },
	{ value: 'HT', label: 'Haiti (+509)' },
	{ value: 'HN', label: 'Honduras (+504)' },
	{ value: 'HK', label: 'Hong Kong (+852)' },
	{ value: 'HU', label: 'Hungary (+36)' },
	{ value: 'IS', label: 'Iceland (+354)' },
	{ value: 'IN', label: 'India (+91)' },
	{ value: 'ID', label: 'Indonesia (+62)' },
	{ value: 'IR', label: 'Iran (+98)' },
	{ value: 'IQ', label: 'Iraq (+964)' },
	{ value: 'IE', label: 'Ireland (+353)' },
	{ value: 'IM', label: 'Isle of Man (+44-1624)' },
	{ value: 'IL', label: 'Israel (+972)' },
	{ value: 'IT', label: 'Italy (+39)' },
	{ value: 'CI', label: 'Ivory Coast (+225)' },
	{ value: 'JM', label: 'Jamaica (+1-876)' },
	{ value: 'JP', label: 'Japan (+81)' },
	{ value: 'JE', label: 'Jersey (+44-1534)' },
	{ value: 'JO', label: 'Jordan (+962)' },
	{ value: 'KZ', label: 'Kazakhstan (+7)' },
	{ value: 'KE', label: 'Kenya (+254)' },
	{ value: 'KI', label: 'Kiribati (+686)' },
	{ value: 'XK', label: 'Kosovo (+383)' },
	{ value: 'KW', label: 'Kuwait (+965)' },
	{ value: 'KG', label: 'Kyrgyzstan (+996)' },
	{ value: 'LA', label: 'Laos (+856)' },
	{ value: 'LV', label: 'Latvia (+371)' },
	{ value: 'LB', label: 'Lebanon (+961)' },
	{ value: 'LS', label: 'Lesotho (+266)' },
	{ value: 'LR', label: 'Liberia (+231)' },
	{ value: 'LY', label: 'Libya (+218)' },
	{ value: 'LI', label: 'Liechtenstein (+423)' },
	{ value: 'LT', label: 'Lithuania (+370)' },
	{ value: 'LU', label: 'Luxembourg (+352)' },
	{ value: 'MO', label: 'Macao (+853)' },
	{ value: 'MK', label: 'Macedonia (+389)' },
	{ value: 'MG', label: 'Madagascar (+261)' },
	{ value: 'MW', label: 'Malawi (+265)' },
	{ value: 'MY', label: 'Malaysia (+60)' },
	{ value: 'MV', label: 'Maldives (+960)' },
	{ value: 'ML', label: 'Mali (+223)' },
	{ value: 'MT', label: 'Malta (+356)' },
	{ value: 'MH', label: 'Marshall Islands (+692)' },
	{ value: 'MR', label: 'Mauritania (+222)' },
	{ value: 'MU', label: 'Mauritius (+230)' },
	{ value: 'YT', label: 'Mayotte (+262)' },
	{ value: 'MX', label: 'Mexico (+52)' },
	{ value: 'FM', label: 'Micronesia (+691)' },
	{ value: 'MD', label: 'Moldova (+373)' },
	{ value: 'MC', label: 'Monaco (+377)' },
	{ value: 'MN', label: 'Mongolia (+976)' },
	{ value: 'ME', label: 'Montenegro (+382)' },
	{ value: 'MS', label: 'Montserrat (+1-664)' },
	{ value: 'MA', label: 'Morocco (+212)' },
	{ value: 'MZ', label: 'Mozambique (+258)' },
	{ value: 'MM', label: 'Myanmar (+95)' },
	{ value: 'NA', label: 'Namibia (+264)' },
	{ value: 'NR', label: 'Nauru (+674)' },
	{ value: 'NP', label: 'Nepal (+977)' },
	{ value: 'NL', label: 'Netherlands (+31)' },
	{ value: 'AN', label: 'Netherlands Antilles (+599)' },
	{ value: 'NC', label: 'New Caledonia (+687)' },
	{ value: 'NZ', label: 'New Zealand (+64)' },
	{ value: 'NI', label: 'Nicaragua (+505)' },
	{ value: 'NE', label: 'Niger (+227)' },
	{ value: 'NG', label: 'Nigeria (+234)' },
	{ value: 'NU', label: 'Niue (+683)' },
	{ value: 'KP', label: 'North Korea (+850)' },
	{ value: 'MP', label: 'Northern Mariana Islands (+1-670)' },
	{ value: 'NO', label: 'Norway (+47)' },
	{ value: 'OM', label: 'Oman (+968)' },
	{ value: 'PK', label: 'Pakistan (+92)' },
	{ value: 'PW', label: 'Palau (+680)' },
	{ value: 'PS', label: 'Palestine (+970)' },
	{ value: 'PA', label: 'Panama (+507)' },
	{ value: 'PG', label: 'Papua New Guinea (+675)' },
	{ value: 'PY', label: 'Paraguay (+595)' },
	{ value: 'PE', label: 'Peru (+51)' },
	{ value: 'PH', label: 'Philippines (+63)' },
	{ value: 'PN', label: 'Pitcairn (+64)' },
	{ value: 'PL', label: 'Poland (+48)' },
	{ value: 'PT', label: 'Portugal (+351)' },
	{ value: 'PR', label: 'Puerto Rico (+1-787 and +1-939)' },
	{ value: 'QA', label: 'Qatar (+974)' },
	{ value: 'CG', label: 'Republic of the Congo (+242)' },
	{ value: 'RE', label: 'Reunion (+262)' },
	{ value: 'RO', label: 'Romania (+40)' },
	{ value: 'RU', label: 'Russia (+7)' },
	{ value: 'RW', label: 'Rwanda (+250)' },
	{ value: 'BL', label: 'Saint Barthelemy (+590)' },
	{ value: 'SH', label: 'Saint Helena (+290)' },
	{ value: 'KN', label: 'Saint Kitts and Nevis (+1-869)' },
	{ value: 'LC', label: 'Saint Lucia (+1-758)' },
	{ value: 'MF', label: 'Saint Martin (+590)' },
	{ value: 'PM', label: 'Saint Pierre and Miquelon (+508)' },
	{ value: 'VC', label: 'Saint Vincent and the Grenadines (+1-784)' },
	{ value: 'WS', label: 'Samoa (+685)' },
	{ value: 'SM', label: 'San Marino (+378)' },
	{ value: 'ST', label: 'Sao Tome and Principe (+239)' },
	{ value: 'SA', label: 'Saudi Arabia (+966)' },
	{ value: 'SN', label: 'Senegal (+221)' },
	{ value: 'RS', label: 'Serbia (+381)' },
	{ value: 'SC', label: 'Seychelles (+248)' },
	{ value: 'SL', label: 'Sierra Leone (+232)' },
	{ value: 'SG', label: 'Singapore (+65)' },
	{ value: 'SX', label: 'Sint Maarten (+1-721)' },
	{ value: 'SK', label: 'Slovakia (+421)' },
	{ value: 'SI', label: 'Slovenia (+386)' },
	{ value: 'SB', label: 'Solomon Islands (+677)' },
	{ value: 'SO', label: 'Somalia (+252)' },
	{ value: 'ZA', label: 'South Africa (+27)' },
	{ value: 'KR', label: 'South Korea (+82)' },
	{ value: 'SS', label: 'South Sudan (+211)' },
	{ value: 'ES', label: 'Spain (+34)' },
	{ value: 'LK', label: 'Sri Lanka (+94)' },
	{ value: 'SD', label: 'Sudan (+249)' },
	{ value: 'SR', label: 'Suriname (+597)' },
	{ value: 'SJ', label: 'Svalbard and Jan Mayen (+47)' },
	{ value: 'SZ', label: 'Swaziland (+268)' },
	{ value: 'SE', label: 'Sweden (+46)' },
	{ value: 'CH', label: 'Switzerland (+41)' },
	{ value: 'SY', label: 'Syria (+963)' },
	{ value: 'TW', label: 'Taiwan (+886)' },
	{ value: 'TJ', label: 'Tajikistan (+992)' },
	{ value: 'TZ', label: 'Tanzania (+255)' },
	{ value: 'TH', label: 'Thailand (+66)' },
	{ value: 'TG', label: 'Togo (+228)' },
	{ value: 'TK', label: 'Tokelau (+690)' },
	{ value: 'TO', label: 'Tonga (+676)' },
	{ value: 'TT', label: 'Trinidad and Tobago (+1-868)' },
	{ value: 'TN', label: 'Tunisia (+216)' },
	{ value: 'TR', label: 'Turkey (+90)' },
	{ value: 'TM', label: 'Turkmenistan (+993)' },
	{ value: 'TC', label: 'Turks and Caicos Islands (+1-649)' },
	{ value: 'TV', label: 'Tuvalu (+688)' },
	{ value: 'VI', label: 'U.S. Virgin Islands (+1-340)' },
	{ value: 'UG', label: 'Uganda (+256)' },
	{ value: 'UA', label: 'Ukraine (+380)' },
	{ value: 'AE', label: 'United Arab Emirates (+971)' },
	{ value: 'GB', label: 'United Kingdom (+44)' },
	{ value: 'US', label: 'United States (+1)' },
	{ value: 'UY', label: 'Uruguay (+598)' },
	{ value: 'UZ', label: 'Uzbekistan (+998)' },
	{ value: 'VU', label: 'Vanuatu (+678)' },
	{ value: 'VA', label: 'Vatican (+379)' },
	{ value: 'VE', label: 'Venezuela (+58)' },
	{ value: 'VN', label: 'Vietnam (+84)' },
	{ value: 'WF', label: 'Wallis and Futuna (+681)' },
	{ value: 'EH', label: 'Western Sahara (+212)' },
	{ value: 'YE', label: 'Yemen (+967)' },
	{ value: 'ZM', label: 'Zambia (+260)' },
	{ value: 'ZW', label: 'Zimbabwe (+263)' },
];

export const formData = [
	{
		label: "Nombre(s)",
		name: "name",
		required: true,
		message: "Ingresa tu nombre",
		type: "text"
	},
	{
		label: "Apellido paterno",
		name: "lastnameP",
		required: true,
		message: "Ingresa tus apellido paterno",
		type: "text"
	},
	{
		label: "Apellido materno",
		name: "lastnameM",
		required: true,
		message: "Ingresa tus apellido materno",
		type: "text"
	},
	{
		label: "Correo",
		name: "email",
		required: true,
		message: "Ingresa tu correo",
		type: "email"
	},
	{
		label: "Fecha de nacimiento",
		name: "birthday",
		required: true,
		message: "Ingresa tu fecha de nacimiento",
		type: "date"
	},
	{
		label: "Sexo",
		name: "sex",
		required: true,
		message: "Ingresa tu sexo",
		type: "radio",
		catalog: "sex"
	},
	{
		label: "Talla",
		name: "size",
		required: true,
		message: "Ingresa tu talla",
		type: "radio",
		catalog: "size"
	},
	{
		label: "Lugar de origen",
		name: "originPlace",
		required: true,
		message: "Ingresa tu lugar de origen",
		type: "text"
	},
	{
		label: "Club",
		name: "club",
		required: false,
		message: "Ingresa tu club",
		type: "text"
	},
	// {
	// 	label: "País",
	// 	name: "country",
	// 	required: true,
	// 	message: "Ingresa tu país",
	// 	type: "select",
	// 	catalog: "countries"
	// },
	{
		label: "Phone Number",
		name: "phoneNumber",
		required: false,
		message: "Ingresa tu club",
		type: "phone",
		catalog: "countries"
	},
	{
		label: "Emergency Phone Number",
		name: "emergencyPhoneNumber",
		required: false,
		message: "Ingresa tu club",
		type: "phone",
		catalog: "countries"
	},
]

export const catalogs = {
	sex: ["Masculino", "Femenino"],
	size: ["S", "M", "X", "XL"],
	countries: countryOptions
}


export const transporteInfo = [
	{
		tipo: "Transporte Oaxaca - Totontepec",
		persona: "Chimalli Travel 9515012387",
		hora: "Salida Sábado 25 nov. - regreso Domingo 26 nov."
	},
{
		tipo: "Autobús Oaxaca - Totontepec",
		persona: "Erika Montelongo 8681251543",
		hora: "Salida Viernes 24 nov. - regreso Domingo 26 nov."
	},
{
		tipo: "Transporte CDMX - Totontepec",
		persona: "Cirilo Flores 5583427429",
		hora: "Salida Jueves 23 nov 21:00hrs. - regreso Lunes 27 nov. 6:00hrs"
	}
]


export const patrocinadoresInfo = {
	patrocinadores: [
		{image: "./images/patrocinadores/acreimex.png"},
		{image: "./images/patrocinadores/chimalli.jpg"},
		{image: "./images/patrocinadores/cleanplastic.jpg"},
		{image: "./images/patrocinadores/corela.jpeg"},
		{image: "./images/patrocinadores/tutorneza.gif"},
		{image: "./images/patrocinadores/wahaka1.png"},
		{image: "./images/patrocinadores/wahaka2.jpeg"}


	],
	organizadores: [
		{image: "./images/organizadores/conbiodes.png"},
		{image: "./images/organizadores/happyface.png"},
		{image: "./images/organizadores/logo.png"},
		{image: "./images/organizadores/maraton.png"},
		{image: "./images/organizadores/municipal.jpg"},
		{image: "./images/organizadores/salvajes.png"}

	]
}
