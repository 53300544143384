import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './modules/Home';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './modules/About';
import Rutas from './modules/Rutas';
import Convocatoria from './modules/Convocatoria';
import Huellita from './modules/Huellita';
import Contacto from './modules/Contacto';
import Patrocinadores from './modules/Patrocinadores';
// import PantallaPago from './modules/Inscripcion';
import Info from './modules/Informacion';
import Quince from './modules/Rutas/15k';
import Treinta from './modules/Rutas/30k';
import Cincuenta from './modules/Rutas/53k';
import Cien from './modules/Rutas/100k';

const App = () => {
  return (
    <Router>
      <div className='App'>
        <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="rutas" element={<Rutas />}></Route>
        <Route path="convocatoria" element={<Convocatoria />}></Route>
        <Route path="huellita" element={<Huellita />}></Route>
        <Route path="contacto" element={<Contacto />}></Route>
        <Route path="patrocinadores" element={<Patrocinadores />}></Route>
        {/* <Route path="inscripcion" element={<PantallaPago />}></Route> */}
        <Route path="info" element={<Info />}></Route>
        <Route path="100k" element={<Cien />}></Route>
        <Route path="53k" element={<Cincuenta />}></Route>
        <Route path="30k" element={<Treinta />}></Route>
        <Route path="15k" element={<Quince />}></Route>
          {/* <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route component={NotFound} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;