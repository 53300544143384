import React, { Component } from 'react'
import { Layout } from 'antd';

import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';
import Video from '../../Components/Video';

const { Content } = Layout;

const Huellita = () => {


    return (
        <Layout className="layout">
            <HeaderComponent />
            <Content>
                <Title title={"Huellita de tapir"} />
                <div className='one'>

                    {/* <div className='huellita-text'>
                        <p>
                            <p>EL “COLECTIVO AYÖÖK AAJ YAKVA’KXTÜKPA, MIXES TENDIENDO REDES” EN COORDINACIÓN CON LOS ORGANIZADORES DEL ULTRAMARATÓN SIERRA MIXE, INVITAN A TODAS LAS PERSONAS QUE PERTENEZCAN AL MUNICIPIO DE TOTONTEPEC VILLA DE MORELOS MIXE, ESCRITORES, COMPOSITORES E INTERESADOS EN LA CULTURA E IDENTIDAD MIXE, A PARTICIPAR EN EL CONCURSO “HIMNO AL ULTRAMARATÓN SIERRA MIXE” QUE SE LLEVARÁ BAJO LAS SIGUIENTES BASES.</p>

                            <p>1.- PODRÁN CONCURSAR JÓVENES Y ADULTOS HABLANTES DE LA LENGUA AYÖÖK QUE SEAN ORIGINARIOS Y/O DESCENDIENTES DEL MUNICIPIO DE TOTONTEPEC VILLA DE MORELOS MIXE Y DE SUS DIEZ AGENCIAS MUNICIPALES Y DE POLICÍA, INDEPENDIENTEMEMNTE DEL LUGAR DE RESIDENCIA.</p>

                            <p>2.- LA LETRA DEL HIMNO SE DEBE PRESENTAR EN LENGUA MIXE, “VARIANTE MIXE ALTO DEL NORTE” Y SE ACOMPAÑARÁ DE LA TRADUCCIÓN EN LENGUA CASTELLANA, DEBERÁ CONTENER CUANDO MENOS TRES ESTROFAS Y UN ESTRIBILLO, PROCURANDO QUE LA ESTRUCTURA Y RÍTMICA COINCIDAN EN AMBAS LENGUAS. LA OBRA DEBE SER INEDITA Y SE DESCALIFICARÁN LOS TRABAJOS QUE SE PAREZCAN A OBRAS EXISTENTES. (SI EL CONCURSANTE TIENE DIFICULTADES PARA ESCRIBIR SU TRABAJO EN LENGUA MIXE, PODRÁ SOLICITAR EL APOYO CORRESPONDIENTE AL “COLECTIVO AYÖÖK AAJ YAKVA’KXTÜKPA” PARA LA ELABORACIÓN DE SU TRABAJO).</p>

                            <p>3.- LA PRESENTACIÓN DE LOS TRABAJOS ESCRITOS SE ACOMPAÑARÁ DE UN DISCO COMPACTO O USB EN FORMATO WMA O MP3 QUE PUEDA SER REPRODUCIDO EN CUALQUIER DISPOSITIVO DE AUDIO CON ACOMPAÑAMIEMTO DE CUALQUIER INSTRUMENTO MUSICAL, PARA QUE EL JURADO APRECIE LAS CARACTERÍSTICAS DEL HIMNO. SI EL TRABAJO ES ENVIADO POR CORREO ELECTRÓNICO, SE DEBERÁ AÑADIR LA LETRA Y LA GRABACIÓN COMO ARCHIVOS ADJUNTOS.</p>

                            <p>4.- LOS TRABAJOS PUEDEN ENTREGARSE EN FORMA FÍSICA O POR CORREO ELECTRÓNICO.</p>
                            <p>•	LAS OBRAS QUE SE ENVÍEN POR CORREO ELECTRÓNICO DEBERAN ANOTAR EN EL CUERPO DEL CORREO ELECTRÓNICO LOS SIGUIENTES DATOS: ** NOMBRE COMPLETO DEL AUTOR, SEUDÓNIMO, DOMICILIO, CORREO ELECTRONICO Y TELEFONO DE CONTACTO, ANEXANDO COPIA DE IDENTIFICACIÓN OFICIAL, ASI COMO LA CONSTANCIA DE CONSENTIMIENTO, DEBIDAMENTE FIRMADA, QUE SE SOLICITA A LOS PARTICIPANTES. EL CORREO AL QUE SE ENVIARÁN ES: colectivovitsvakxpa@gmail.com</p>

                            <p>•	LA ENTREGA EN FORMA FÍSICA SE HARÁ EN LA PRESIDENCIA MUNICIPAL O REGIDURIA DE EDUCACIÓN Y SE HARÁ EN SOBRE CERRADO QUE CONTENGA EL TRABAJO ESCRITO Y EN DISPOSITIVO DE AUDIO CON LAS CARACTERISTICAS DESCRITAS Y EN EL EXTERIOR DEL SOBRE SOLO SE ANOTARÁ EL NOMBRE DE LA OBRA Y EL SEUDÓNIMO DEL AUTOR, EN UN SOBRE APARTE SE ENTREGARÁ UNA HOJA CON LOS DATOS DEL AUTOR SEÑALADOS** Y LA COPIA DE LA IDENTIFICACIÓN OFICIAL , ASI COMO LA CONSTANCIA DE CONSENTIMIENTO, DEBIDAMENTE FIRMADA, QUE SE SOLICITA A LOS PARTICIPANTES. LA DIRECCIÓN DE ENTREGA ES: DOMICILIO CONOCIDO, PALACIO MUNICIPAL DE TOTONTEPEC VILLA DE MORELOS.</p>

                            <p>5.- LA RECEPCIÓN DE LAS PROPUESTAS INICIARÁN A PARTIR DE LA PUBLICACIÓN DE ESTA CONVOCATORIA Y SE CERRARÁ EL 30 DE SEPTIEMBRE DEL 2022. SI SE ENVÍAN LOS TRABAJOS POR MENSAJERÍA O CORREO POSTAL, EL MATASELLOS NO DEBERÁ REBASAR LA FECHA DE CIERRE. LA PUBLICACION DEL TRABAJO GANADOR SE DARA A CONOCER EL 21 DE OCTUBRE DEL 2022 Y SE NOTIFICARÁ VIA CORREO ELECTRONICO AL GANADOR A MAS TARDAR AL SIGUIENTE DIA DE LA EMISION DEL FALLO. TAMBIÉN SE DARA A CONOCER EN LA PAGINA DE FASEBOOK DEL COLECTIVO (Anyukojmit Aaj Yakva’kxtükpa). </p>

                            <p>6.- LOS TRABAJOS SERÁN ANALIZADOS Y EVALUADOS POR UN JURADO CALIFICADOR, CONOCEDORES DE LA CULTURA Y MÚSICA MIXE, INDEPENDIENTES DE LOS INTEGRANTES DEL COLECTIVO CONVOCANTE Y SU FALLO SERÁ INAPELABLE.</p>

                            <p>7.- LA ACEPTACIÓN DE LAS BASES DEL CONCURSO Y LOS TÉRMINOS DE LA PRESENTE CONVOCATORIA, IMPLICA QUE TODOS LOS CONCURSANTES OTORGAN SU ANUENCIA PARA QUE SU OBRA SEA UTILIZADA, SI SE HACE NECESARIO PARA FINES DE DESARROLLO Y PROMOCION DE LA CULTURA Y LENGUA MIXE. POR SU PARTE EL AUTOR DEL TRABAJO GANADOR OTORGA SU PERMISO PARA QUE ESTE SE CONVIERTA EN EL HIMNO OFICIAL DEL ULTRAMARATÓN SIERRA MIXE Y QUE ACEPTA PARA QUE EN CASO NECESARIO SE HAGAN PEQUEÑAS ADECUACIONES PARA SU INTERPRETACION ACOMPAÑADO DEL GRUPO MUSICAL DE QUE SE TRATE, SIN QUE SE MODIFIQUE SUSTANCIALMENTE LA PROPUESTA ORIGINAL,ADEMÁS QUE ESTE SEA INTERPRETADO EN LA VARIANTE DE TOTONTEPEC, INDEPENDIENTEMENTE QUE SE PUEDA INTERPRETAR EN OTRAS VARIANTES DE NUESTRA LENGUA MIXE E INCLUSO DE OTRAS LENGUAS INDÍGENAS SIEMPRE Y CUANDO SE RESPETEN LOS CRÉDITOS DEL AUTOR.</p>

                            <p>8.- EL TRABAJO GANADOR DEL 	“HIMNO AL ULTRAMARATÓN SIERRA MIXE” TENDRÁ UN PREMIO DE $5,000.00 (CINCO MIL PESOS M.N.) QUE SERÁ ENTREGADO EN EFECTIVO O EN DEPÓSITO BANCARIO SEGÚN SEA EL CASO.</p>

                            <p>6.- EL HIMNO GANADOR SERÁ PRESENTADO OFICIALMENTE EN EL PROGRAMA DE INAUGURACION DEL ULTRAMARATÓN SIERRA MIXE.</p>

                            <p>A  T  E  N  T  A  M  E  N  T E</p>

                            <p>“COLECTIVO AYÖÖK AAJ YAKVA’KXTÜKPA, MIXES TENDIENDO REDES”</p>

                            <p>TOTONTEPEC VILLA DE MORELOS, MIXE, JULIO DE 2022</p>

                        </p>
                    </div> */}
                    <div className='huellita-text'>

                    <h2  className='subtitle'>CARRERA INFANTIL “HUELLITA DE TAPIR”</h2>

<p>Desde la 3a edición nos dimos cuenta de la necesidad de realizar un evento incluyente con la niñez. La carrera Huellita de Tapir ha tenido gran éxito y busca promover el espíritu deportivo entre los grandes atletas del futuro. Con tu aportación ayudas a que este evento llegue a más niños y familias. </p>

<p></p><b>Sábado 25 de noviembre, 10:00 am.</b>

<h3  className='subtitle-o'>CATEGORÍAS Y DISTANCIAS</h3>
<p><b>Colibríes:</b> 100m - 4 a 5 años</p>
<p><b>Ardillas:</b> 200m - 6 a 7 años</p>
<p><b>Tigrillos:</b> 500m  - 8 a 9 años</p>
<p><b>Jaguares:</b> 600m - 10 a 11 años</p>
<p><b>Tapires:</b> 1km - 12 a 13 años</p>

                    </div>
                    <Video path={'videos/huellita.mp4'}/>

                </div>
            </Content>
            <FooterComponent />
        </Layout>
    );
};



export default Huellita;