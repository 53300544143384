import React from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';
import SocialLinks from '../Social';
import { Roll } from 'react-reveal';
const { Footer } = Layout;



function FooterComponent(params) {
	return (<Footer style={{ textAlign: 'center'	 }} className='footer'>
		<Row justify="center">
			<Col xs={6} sm={6} md={6} lg={6} xl={6}>
			</Col>
			<Col xs={6} sm={6} md={6} lg={6} xl={6}>
				<Row justify={"center"}>
					<SocialLinks />
				</Row>
			</Col>
			<Col xs={6} sm={6} md={6} lg={6} xl={6}>
				<Row justify="end">
					{routes.map((routes) => {
						return <Col xs={24} sm={24} md={24} lg={24} xl={24}>
							{/* <Roll left> */}

							<Link to={routes.route} className='footer-pages'>{routes.label}</Link>
							{/* </Roll> */}
						</Col>
					})}
				</Row>
			</Col>
			<Col xs={6} sm={6} md={6} lg={6} xl={6}>
			</Col>
		</Row>
		<div style={{ marginTop: '16px' }}>
			Ultra maratón sierra mixe © {new Date().getFullYear()} | All rights reserved
		</div>
	</Footer>
	)
}

export default FooterComponent