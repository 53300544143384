import React from 'react';
import { Layout, Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';
import {Image} from 'antd';

const { Content } = Layout;
const { Meta } = Card;

const Contacto = () => {

	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"Contacto"} />
				<div className='one'>
				<Image
										// width={200}
										src="./images/convocatoria/18.jpg"
										style={{ borderRadius: 20 }}
									/>
													<Image
										// width={200}
										src="./contact.jpg"
										style={{ borderRadius: 20, width: "90%", maxWidth: 600, margin: 100 }}
									/>
				</div>
			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Contacto;