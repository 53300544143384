import React, { useState } from 'react';
import { Layout, Card, Avatar, Modal, Image } from 'antd';
import { rutasInformacion } from '../../constants';
import HeaderComponent from '../../Components/Header';
import FooterComponent from '../../Components/Footer';
import Title from '../../Components/Title';
import { faLocationDot, faMountainSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import GPXMap from '../../Components/GpxMap';
import Fade from "react-reveal"
import Video from '../../Components/Video';

const { Content } = Layout;
const { Meta } = Card;

const Rutas = () => {
	const [open, setOpen] = useState(false);
	const [ruta, setRuta] = useState("")

	return (
		<Layout className="layout">
			<HeaderComponent />
			<Content>
				<Title title={"Rutas"} />
				<div className='two'>

					<div className="rutas-content">
						<div>
							{rutasInformacion.map((ruta, i) => {
								if (i % 2 == 0) {

									return (
										<Fade left>
											<Card
												hoverable
												style={{
													width: "90%",
													maxWidth: 400,
													margin: 20
												}}
												cover={
													// <Link to={"/" + ruta.title}>
													<img
														alt="example"
														src={ruta.titleimg}
													/>
													// </Link>
												}
												actions={[
													<div onClick={() => { setOpen(true); setRuta(ruta.title) }}>Mapa <FontAwesomeIcon icon={faLocationDot} /></div>,
													<div onClick={() => { setOpen(true); setRuta(ruta.title + "alt") }}>Altimetría <FontAwesomeIcon icon={faMountainSun} /></div>,
												]}
											>
												<Meta
													// avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
													title={<h2 className='subtitle'>{ruta.title}</h2>}
													description={<p><ul>{ruta.desc1.map((e) => <li>{e}</li>)}</ul>{ruta.desc2}</p>}
												/>
												<p>{ruta.info}</p>
											</Card>

										</Fade>

									)
								} else {

									return (
										<Fade right>
											<Card
												hoverable
												style={{
													width: "90%",
													maxWidth: 400,
													margin: 20,
												}}
												cover={
													// <Link to={"/" + ruta.title}>
													<img
														alt="example"
														src={ruta.titleimg}
													/>
													// </Link>
												}
												actions={[
													<div onClick={() => { setOpen(true); setRuta(ruta.title) }}>Mapa <FontAwesomeIcon icon={faLocationDot} /></div>,
													<div onClick={() => { setOpen(true); setRuta(ruta.title + "alt") }}>Altimetría <FontAwesomeIcon icon={faMountainSun} /></div>,
												]}
											>
												<Meta
													// avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
													title={<h2 className='subtitle'>{ruta.title}</h2>}
													description={<p><ul>{ruta.desc1.map((e) => <li>{e}</li>)}</ul>{ruta.desc2}</p>}
												/>
												<p>{ruta.info}</p>
											</Card>

										</Fade>

									)
								}

							})}
						</div>
					</div>
				</div>
				<Modal
					title=""
					centered
					open={open}
					onOk={() => setOpen(false)}
					onCancel={() => setOpen(false)}
					width={"80%"}
				>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{ruta.includes("alt") ? <Video path={'videos/' + ruta + ".mp4"} /> : <Image
							// width={800}
							// height={500}
							src={"./images/rutasimg/" + ruta + ".jpg"}
							style={{ borderRadius: 20 }}
							alt={"./images/" + ruta}
						/>}
					</div>
				</Modal>

			</Content>
			<FooterComponent />
		</Layout>
	);
};

export default Rutas;